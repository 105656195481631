module.exports = [{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/friedolin/git/friedolinfoerder/node_modules/gatsby-remark-images","id":"00ff09d7-6c1f-5e0d-a686-3b7e79ef3bc2","name":"gatsby-remark-images","version":"3.1.7","pluginOptions":{"plugins":[],"maxWidth":960},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]},{"resolve":"/home/friedolin/git/friedolinfoerder/node_modules/gatsby-remark-images-medium-zoom","id":"9a661529-a37d-52cd-b9a8-f94d932ba2e7","name":"gatsby-remark-images-medium-zoom","version":"1.2.1","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"globalScope":"\n          import components from '/home/friedolin/git/friedolinfoerder/src/page-components'; \n          export default components;\n        ","extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-unwrap-images"},{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-images-medium-zoom","options":{}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}]},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/friedolin/git/friedolinfoerder/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
